var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"mt-3 mx-3 elevation-4"},[_c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function({ handleSubmit }){return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('i-toolbar',{attrs:{"dontSave":"","title":_vm.order.pk != ''
            ? `(${_vm.$t(_vm.order.mainStatus)}) ${_vm.$tc('purchase_order', 1)} ${
                _vm.order.code
              }`
            : `(${_vm.$t(_vm.order.mainStatus)}) ${_vm.$t('add', {
                value: _vm.$tc('purchase_order', 1)
              })}`,"loading":_vm.loading},scopedSlots:_vm._u([{key:"arrowLeft",fn:function(){return [_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('i-btn',{attrs:{"text":"","small":_vm.$vuetify.breakpoint.smAndDown,"icon":"icon-request","title":_vm.$t('purchase_order'),"color":"text-dark"},on:{"click":function($event){return _vm.$router.back()}}}),(_vm.step > 1)?_c('i-btn',{attrs:{"text":"","small":_vm.$vuetify.breakpoint.smAndDown,"icon":"fa-arrow-left","title":_vm.$t('back'),"color":"text-dark"},on:{"click":_vm.back}}):_vm._e()]},proxy:true},{key:"arrowRight",fn:function(){return [(
              _vm.step < 3 ||
              (_vm.order.mainStatus === 'in_process' && _vm.step < 3) ||
              (_vm.order.mainStatus === 'pdocument' && _vm.step < 3)
            )?_c('i-btn',{attrs:{"text":"","small":_vm.$vuetify.breakpoint.smAndDown,"icon":"fa-arrow-right","title":_vm.$t('next'),"color":"text-dark"},on:{"click":function($event){return _vm.next()}}}):_vm._e()]},proxy:true},{key:"submit",fn:function(){return [(_vm.order.pk != '')?_c('i-btn',{attrs:{"text":"","title":_vm.$tc('save'),"small":_vm.$vuetify.breakpoint.smAndDown,"icon":"fa-save","color":"text-dark","loading":_vm.loading,"type":"submit"},on:{"click":function($event){return _vm.submit()}}}):_vm._e()]},proxy:true}],null,true)}),_c('v-card-text',{staticClass:"px-0"},[_c('v-stepper',{staticClass:"elevation-0",staticStyle:{"z-index":"0"},model:{value:(_vm.step),callback:function ($$v) {_vm.step=$$v},expression:"step"}},[_c('v-stepper-header',{staticClass:"elevation-0"},[_c('v-stepper-step',{attrs:{"step":1,"edit-icon":"$complete","editable":""}},[_vm._v(" "+_vm._s(_vm.$t('basic'))+" ")]),_c('v-divider'),(_vm.orderBtn.product)?_c('v-stepper-step',{attrs:{"step":2,"complete":_vm.order.mainStatus !== 'draft' && _vm.step !== 2}},[_vm._v(" "+_vm._s(_vm.$t('selection', { value: _vm.$tc('product', 2) }))+" ")]):_vm._e(),_c('v-divider'),(_vm.orderBtn.document)?_c('v-stepper-step',{attrs:{"step":3,"editable":_vm.order.mainStatus !== 'draft',"edit-icon":"$complete"}},[_vm._v(" "+_vm._s(_vm.$t('purchase_order'))+" ")]):_vm._e()],1),_c('v-stepper-items',[_c('v-stepper-content',{attrs:{"step":1}},[_c('v-row',{staticClass:"mt-0 pb-6"},[_c('v-col',{staticClass:"align-center justify-center align-self-center",attrs:{"cols":"12","md":"5"}},[(_vm.loading || _vm.loadingIMG)?_c('v-skeleton-loader',{attrs:{"type":"image","tile":""}}):_c('i-image',{attrs:{"readonly":_vm.order.orderMain == 'canceled' ||
                      _vm.order.orderMain == 'finished',"file":_vm.order.thumbnail},on:{"update:file":function($event){return _vm.$set(_vm.order, "thumbnail", $event)}},model:{value:(_vm.imageUrl),callback:function ($$v) {_vm.imageUrl=$$v},expression:"imageUrl"}})],1),_c('v-col',{attrs:{"cols":"12","md":"7"}},[_c('v-row',[(_vm.orderBtn.supplier)?_c('v-col',{attrs:{"cols":"12","md":_vm.orderBtn.supplier &&
                        _vm.order.supplier != null &&
                        _vm.infoSupplier != null &&
                        _vm.infoSupplier.email != undefined
                          ? 6
                          : 12}},[_c('dialsupplier',{attrs:{"container":"modal"},on:{"updated":_vm.addSupplier},model:{value:(_vm.infoSupplierfield),callback:function ($$v) {_vm.infoSupplierfield=$$v},expression:"infoSupplierfield"}}),_c('ValidationProvider',{attrs:{"vid":"supplier","rules":"required","name":_vm.$tc('supplier', 1)},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [(_vm.loading)?_c('v-skeleton-loader',{attrs:{"type":"list-item-avatar","tile":""}}):_vm._e(),_c('v-autocomplete',{directives:[{name:"show",rawName:"v-show",value:(!_vm.loading),expression:"!loading"}],key:"supplier-input",attrs:{"append-icon":"fa-search","autocomplete":"off","color":"secondary","item-text":"company_name","item-value":"pk","outlined":"","clearable":_vm.order.mainStatus === 'draft',"disabled":_vm.order.mainStatus !== 'draft',"error-messages":errors[0],"items":_vm.suppliers,"label":_vm.$tc('supplier', 1),"prepend-inner-icon":_vm.order.mainStatus === 'draft' &&
                            _vm.orderBtn.supplierAdd &&
                            _vm.orderBtn.terms
                              ? 'fa-plus'
                              : ''},on:{"click:prepend-inner":function($event){_vm.infoSupplierfield = true}},model:{value:(_vm.order.supplier),callback:function ($$v) {_vm.$set(_vm.order, "supplier", $$v)},expression:"order.supplier"}})]}}],null,true)})],1):_vm._e(),(
                        _vm.infoSupplier != null &&
                        _vm.infoSupplier.email != undefined
                      )?_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"color":"secondary","disabled":"","outlined":"","label":_vm.$tc('email', 1)},model:{value:(_vm.infoSupplier.email),callback:function ($$v) {_vm.$set(_vm.infoSupplier, "email", $$v)},expression:"infoSupplier.email"}})],1):_vm._e(),(
                        _vm.orderBtn.supplier &&
                        _vm.order.supplier != null &&
                        _vm.infoSupplier != null &&
                        _vm.infoSupplier.contacts != undefined
                      )?_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"color":"secondary","disabled":"","outlined":"","label":_vm.$tc('contact', 1),"value":`${_vm.infoSupplier.first_name} ${_vm.infoSupplier.last_name}`}})],1):_vm._e(),_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"vid":"order_name","name":_vm.$tc('order_name', 1),"rules":"required|max:32"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [(_vm.loading)?_c('v-skeleton-loader',{attrs:{"type":"text","tile":""}}):_c('v-text-field',{attrs:{"outlined":"","validate-on-blur":"","error-messages":errors[0],"color":"secondary","label":_vm.$t('purchase_order_name'),"disabled":_vm.order.mainStatus != 'draft'},model:{value:(_vm.order.nameOrder),callback:function ($$v) {_vm.$set(_vm.order, "nameOrder", $$v)},expression:"order.nameOrder"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('i-date-picker',{attrs:{"loading":_vm.loading,"label":_vm.$tc('dateOrder', 1),"disabled":_vm.order.mainStatus != 'draft',"color":"primary","max":new Date().toISOString().substr(0, 10)},model:{value:(_vm.order.date),callback:function ($$v) {_vm.$set(_vm.order, "date", $$v)},expression:"order.date"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('i-date-picker',{attrs:{"color":"primary","disabled":_vm.order.mainStatus != 'draft',"label":_vm.$tc('expected_date', 1),"loading":_vm.loading,"min":_vm.order.date},model:{value:(_vm.order.expected_date),callback:function ($$v) {_vm.$set(_vm.order, "expected_date", $$v)},expression:"order.expected_date"}})],1),(_vm.order.mainStatus === 'finished')?_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"disabled":"","outlined":"","prepend-icon":"fa-calendar","readonly":"","label":_vm.$tc('dateDone', 1),"value":_vm.dateFormat(_vm.order.dateDone)}})],1):_vm._e()],1)],1)],1),(
                  _vm.orderBtn.supplier &&
                  _vm.order.supplier != null &&
                  _vm.infoSupplier != null &&
                  _vm.infoSupplier.address_info != undefined
                )?_c('v-row',_vm._l((_vm.infoSupplier.address_info),function(address,inx){return _c('v-col',{key:`address-${inx}`,attrs:{"cols":"12","md":"6"}},[_c('v-card',{staticClass:"elevation-1 infocard"},[_c('v-card-text',{class:'primary--text'},[_c('h3',{staticClass:"flex-title h6 mb-4"},[_c('span',[_vm._v(" "+_vm._s(address.address_type === 'DISP' ? _vm.$t('dispatchAddress') : _vm.$t('company_address'))+" ")]),_c('span',[_c('v-icon',{attrs:{"color":"primary"},domProps:{"textContent":_vm._s(
                              address.address_type == 'DISP'
                                ? 'fa fa-shipping-fast'
                                : 'fa fa-money-bill'
                            )}})],1)]),_c('span',[_vm._v(_vm._s(`${address.address1}, `))]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(address.address2 != ''),expression:"address.address2 != ''"}]},[_vm._v(" "+_vm._s(`${address.address2}, `)+" ")]),_c('span',[_vm._v(_vm._s(`${address.city}, `))]),_c('span',[_vm._v(_vm._s(`${address.state}, `))]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(address.zip_code != ''),expression:"address.zip_code != ''"}]},[_vm._v(" "+_vm._s(`${address.zip_code}`)+" ")]),_c('span',[_vm._v(" "+_vm._s(_vm.countries[address.country] != undefined ? _vm.countries[address.country] : '')+" ")])])],1)],1)}),1):_vm._e()],1),_c('v-stepper-content',{staticClass:"pa-0",attrs:{"step":2}},[_c('v-row',{attrs:{"no-gutters":_vm.$vuetify.breakpoint.xsOnly}},[(
                    _vm.order.pk !== '' &&
                    (_vm.order.mainStatus === 'draft' ||
                      _vm.order.mainStatus === 'pdocument')
                  )?_c('v-col',{attrs:{"cols":"12","md":_vm.showCart ? 8 : 12}},[(_vm.orderBtn.product)?_c('cart',{attrs:{"mode":"cart","type":"purchase","floatElement":_vm.showCart,"status-order":_vm.order.mainStatus,"order":_vm.order.pk,"orderName":_vm.order.nameOrder,"orderImage":_vm.orderImage,"customer":_vm.infoSupplier,"customerTax":_vm.infoSupplier === null ? false : _vm.infoSupplier.tax}}):_vm._e()],1):_vm._e()],1),(_vm.orderBtn.orderstatus)?_c('shoplist',{attrs:{"type":"purchase","cartlist":_vm.cartlist,"subtotalOrder":_vm.order.subtotal,"totalOrder":_vm.order.total,"taxOrder":_vm.order.tax,"orderName":_vm.order.nameOrder,"orderImage":_vm.orderImage,"type_customer":null,"infoSupplierTax":_vm.infoSupplier === null ? false : _vm.infoSupplier.tax,"status":_vm.order.mainStatus,"order":_vm.order.pk},on:{"update:cartlist":function($event){_vm.cartlist=$event},"update:subtotalOrder":function($event){return _vm.$set(_vm.order, "subtotal", $event)},"update:subtotal-order":function($event){return _vm.$set(_vm.order, "subtotal", $event)},"update:totalOrder":[function($event){return _vm.$set(_vm.order, "total", $event)},function($event){_vm.internalChange = true}],"update:total-order":function($event){return _vm.$set(_vm.order, "total", $event)},"update:taxOrder":function($event){return _vm.$set(_vm.order, "tax", $event)},"update:tax-order":function($event){return _vm.$set(_vm.order, "tax", $event)}},model:{value:(_vm.showCart),callback:function ($$v) {_vm.showCart=$$v},expression:"showCart"}}):_vm._e()],1),_c('v-stepper-content',{staticClass:"pa-0",attrs:{"step":3}},[(
                  _vm.orderBtn.document &&
                  _vm.order.pk != '' &&
                  _vm.order.supplier != null &&
                  (_vm.step === 3 || _vm.order.mainStatus !== 'draft') &&
                  _vm.infoSupplier != null &&
                  _vm.infoSupplier.address_info != undefined
                )?_c('purchasedocument',{attrs:{"status":_vm.order.mainStatus,"cartlist":_vm.cartlist,"order":_vm.order,"infoSupplier":_vm.infoSupplier === null ? '' : _vm.infoSupplier},on:{"update:status":[function($event){return _vm.$set(_vm.order, "mainStatus", $event)},function($event){_vm.internalChange = true}],"update:cartlist":function($event){_vm.cartlist=$event},"update:order":function($event){_vm.order=$event},"done":_vm.getOrder,"subtotalOrder":function($event){_vm.order.subtotal = $event},"totalOrder":function($event){;(_vm.order.total = $event), (_vm.internalChange = true)},"taxOrder":function($event){_vm.order.tax = $event},"addProd":_vm.addProducts}}):_vm._e()],1)],1)],1)],1),_c('v-card-actions',{staticClass:"white",staticStyle:{"position":"fixed","bottom":"0","right":"0"}},[_c('span',{staticClass:"mr-1 primary--text font-weight-black subtitle-2"},[_vm._v(" "+_vm._s(`${_vm.$tc('purchasesReceiver', 1)}: `)+" ")]),_c('span',{staticClass:"mr-2 primary--text subtitle-1"},[_vm._v(" "+_vm._s(_vm.order.name_purchasesReceiver)+" ")])])],1)]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }