<route>
{
  "meta": {
    "permission": [
      "purchase.add_purchaseorder",
      "purchase.change_purchaseorder"
    ]
  }
}
</route>

<template>
  <v-card class="mt-3 mx-3 elevation-4">
    <ValidationObserver v-slot="{ handleSubmit }" ref="form">
      <form @submit.prevent="handleSubmit(submit)">
        <i-toolbar
          dontSave
          :title="
            order.pk != ''
              ? `(${$t(order.mainStatus)}) ${$tc('purchase_order', 1)} ${
                  order.code
                }`
              : `(${$t(order.mainStatus)}) ${$t('add', {
                  value: $tc('purchase_order', 1)
                })}`
          "
          :loading="loading"
        >
          <template v-slot:arrowLeft>
            <v-divider class="mx-4" inset vertical></v-divider>
            <i-btn
              text
              :small="$vuetify.breakpoint.smAndDown"
              icon="icon-request"
              :title="$t('purchase_order')"
              color="text-dark"
              @click="$router.back()"
            >
            </i-btn>
            <i-btn
              v-if="step > 1"
              text
              :small="$vuetify.breakpoint.smAndDown"
              icon="fa-arrow-left"
              :title="$t('back')"
              color="text-dark"
              @click="back"
            >
            </i-btn>
          </template>
          <template v-slot:arrowRight>
            <i-btn
              text
              :small="$vuetify.breakpoint.smAndDown"
              icon="fa-arrow-right"
              :title="$t('next')"
              color="text-dark"
              v-if="
                step < 3 ||
                (order.mainStatus === 'in_process' && step < 3) ||
                (order.mainStatus === 'pdocument' && step < 3)
              "
              @click="next()"
            >
            </i-btn>
          </template>
          <template v-slot:submit>
            <i-btn
              text
              :title="$tc('save')"
              v-if="order.pk != ''"
              :small="$vuetify.breakpoint.smAndDown"
              icon="fa-save"
              color="text-dark"
              :loading="loading"
              type="submit"
              @click="submit()"
            >
            </i-btn>
          </template>
        </i-toolbar>
        <v-card-text class="px-0">
          <v-stepper style="z-index: 0" class="elevation-0" v-model="step">
            <v-stepper-header class="elevation-0">
              <v-stepper-step :step="1" edit-icon="$complete" editable>
                {{ $t('basic') }}
              </v-stepper-step>
              <v-divider></v-divider>
              <v-stepper-step
                v-if="orderBtn.product"
                :step="2"
                :complete="order.mainStatus !== 'draft' && step !== 2"
              >
                {{ $t('selection', { value: $tc('product', 2) }) }}
              </v-stepper-step>
              <v-divider></v-divider>
              <v-stepper-step
                v-if="orderBtn.document"
                :step="3"
                :editable="order.mainStatus !== 'draft'"
                edit-icon="$complete"
              >
                {{ $t('purchase_order') }}
              </v-stepper-step>
            </v-stepper-header>
            <v-stepper-items>
              <v-stepper-content :step="1">
                <v-row class="mt-0 pb-6">
                  <!-- Imagen -->
                  <v-col
                    class="align-center justify-center align-self-center"
                    cols="12"
                    md="5"
                  >
                    <v-skeleton-loader
                      v-if="loading || loadingIMG"
                      type="image"
                      tile
                    ></v-skeleton-loader>
                    <i-image
                      v-else
                      v-model="imageUrl"
                      :readonly="
                        order.orderMain == 'canceled' ||
                        order.orderMain == 'finished'
                      "
                      :file.sync="order.thumbnail"
                    />
                  </v-col>

                  <!-- Demás campos -->
                  <v-col cols="12" md="7">
                    <v-row>
                      <!-- Proveedores -->
                      <v-col
                        v-if="orderBtn.supplier"
                        cols="12"
                        :md="
                          orderBtn.supplier &&
                          order.supplier != null &&
                          infoSupplier != null &&
                          infoSupplier.email != undefined
                            ? 6
                            : 12
                        "
                      >
                        <dialsupplier
                          container="modal"
                          @updated="addSupplier"
                          v-model="infoSupplierfield"
                        ></dialsupplier>
                        <ValidationProvider
                          vid="supplier"
                          rules="required"
                          :name="$tc('supplier', 1)"
                          v-slot="{ errors }"
                        >
                          <v-skeleton-loader
                            v-if="loading"
                            type="list-item-avatar"
                            tile
                          ></v-skeleton-loader>
                          <v-autocomplete
                            append-icon="fa-search"
                            autocomplete="off"
                            color="secondary"
                            item-text="company_name"
                            item-value="pk"
                            key="supplier-input"
                            outlined
                            :clearable="order.mainStatus === 'draft'"
                            :disabled="order.mainStatus !== 'draft'"
                            :error-messages="errors[0]"
                            :items="suppliers"
                            :label="$tc('supplier', 1)"
                            :prepend-inner-icon="
                              order.mainStatus === 'draft' &&
                              orderBtn.supplierAdd &&
                              orderBtn.terms
                                ? 'fa-plus'
                                : ''
                            "
                            @click:prepend-inner="infoSupplierfield = true"
                            v-show="!loading"
                            v-model="order.supplier"
                          ></v-autocomplete>
                        </ValidationProvider>
                      </v-col>

                      <!-- Correo / if supplier -->
                      <v-col
                        v-if="
                          infoSupplier != null &&
                          infoSupplier.email != undefined
                        "
                        cols="12"
                        md="6"
                      >
                        <v-text-field
                          color="secondary"
                          disabled
                          outlined
                          :label="$tc('email', 1)"
                          v-model="infoSupplier.email"
                        />
                      </v-col>

                      <!-- ???? -->
                      <v-col
                        v-if="
                          orderBtn.supplier &&
                          order.supplier != null &&
                          infoSupplier != null &&
                          infoSupplier.contacts != undefined
                        "
                        cols="12"
                      >
                        <v-text-field
                          color="secondary"
                          disabled
                          outlined
                          :label="$tc('contact', 1)"
                          :value="`${infoSupplier.first_name} ${infoSupplier.last_name}`"
                        />
                      </v-col>

                      <!-- Nombre de la orden -->
                      <v-col cols="12">
                        <ValidationProvider
                          vid="order_name"
                          :name="$tc('order_name', 1)"
                          rules="required|max:32"
                          v-slot="{ errors }"
                        >
                          <v-skeleton-loader
                            v-if="loading"
                            type="text"
                            tile
                          ></v-skeleton-loader>
                          <v-text-field
                            v-else
                            outlined
                            validate-on-blur
                            :error-messages="errors[0]"
                            v-model="order.nameOrder"
                            color="secondary"
                            :label="$t('purchase_order_name')"
                            :disabled="order.mainStatus != 'draft'"
                          />
                        </ValidationProvider>
                      </v-col>

                      <!-- Fecha de la orden -->
                      <v-col cols="12" md="6">
                        <i-date-picker
                          :loading="loading"
                          v-model="order.date"
                          :label="$tc('dateOrder', 1)"
                          :disabled="order.mainStatus != 'draft'"
                          color="primary"
                          :max="new Date().toISOString().substr(0, 10)"
                        />
                      </v-col>

                      <!-- Fecha esperada -->
                      <v-col cols="12" md="6">
                        <i-date-picker
                          color="primary"
                          :disabled="order.mainStatus != 'draft'"
                          :label="$tc('expected_date', 1)"
                          :loading="loading"
                          :min="order.date"
                          v-model="order.expected_date"
                        />
                      </v-col>

                      <!-- Fecha de finalización -->
                      <v-col
                        v-if="order.mainStatus === 'finished'"
                        cols="12"
                        md="6"
                      >
                        <v-text-field
                          disabled
                          outlined
                          prepend-icon="fa-calendar"
                          readonly
                          :label="$tc('dateDone', 1)"
                          :value="dateFormat(order.dateDone)"
                        />
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <v-row
                  v-if="
                    orderBtn.supplier &&
                    order.supplier != null &&
                    infoSupplier != null &&
                    infoSupplier.address_info != undefined
                  "
                >
                  <v-col
                    v-for="(address, inx) in infoSupplier.address_info"
                    cols="12"
                    md="6"
                    :key="`address-${inx}`"
                  >
                    <v-card class="elevation-1 infocard">
                      <v-card-text :class="'primary--text'">
                        <h3 class="flex-title h6 mb-4">
                          <span>
                            {{
                              address.address_type === 'DISP'
                                ? $t('dispatchAddress')
                                : $t('company_address')
                            }}
                          </span>
                          <span>
                            <v-icon
                              v-text="
                                address.address_type == 'DISP'
                                  ? 'fa fa-shipping-fast'
                                  : 'fa fa-money-bill'
                              "
                              color="primary"
                            />
                          </span>
                        </h3>
                        <span>{{ `${address.address1}, ` }}</span>
                        <span v-show="address.address2 != ''">
                          {{ `${address.address2}, ` }}
                        </span>
                        <span>{{ `${address.city}, ` }}</span>
                        <span>{{ `${address.state}, ` }}</span>
                        <span v-show="address.zip_code != ''">
                          {{ `${address.zip_code}` }}
                        </span>
                        <span>
                          {{
                            countries[address.country] != undefined
                              ? countries[address.country]
                              : ''
                          }}
                        </span>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
              </v-stepper-content>
              <v-stepper-content class="pa-0" :step="2">
                <v-row :no-gutters="$vuetify.breakpoint.xsOnly">
                  <v-col
                    cols="12"
                    :md="showCart ? 8 : 12"
                    v-if="
                      order.pk !== '' &&
                      (order.mainStatus === 'draft' ||
                        order.mainStatus === 'pdocument')
                    "
                  >
                    <cart
                      v-if="orderBtn.product"
                      mode="cart"
                      type="purchase"
                      :floatElement="showCart"
                      :status-order="order.mainStatus"
                      :order="order.pk"
                      :orderName="order.nameOrder"
                      :orderImage="orderImage"
                      :customer="infoSupplier"
                      :customerTax="
                        infoSupplier === null ? false : infoSupplier.tax
                      "
                    />
                  </v-col>
                </v-row>
                <shoplist
                  v-if="orderBtn.orderstatus"
                  type="purchase"
                  :cartlist.sync="cartlist"
                  :subtotalOrder.sync="order.subtotal"
                  :totalOrder.sync="order.total"
                  @update:totalOrder="internalChange = true"
                  :taxOrder.sync="order.tax"
                  :orderName="order.nameOrder"
                  :orderImage="orderImage"
                  :type_customer="null"
                  :infoSupplierTax="
                    infoSupplier === null ? false : infoSupplier.tax
                  "
                  :status="order.mainStatus"
                  :order="order.pk"
                  v-model="showCart"
                />
              </v-stepper-content>
              <v-stepper-content class="pa-0" :step="3">
                <purchasedocument
                  v-if="
                    orderBtn.document &&
                    order.pk != '' &&
                    order.supplier != null &&
                    (step === 3 || order.mainStatus !== 'draft') &&
                    infoSupplier != null &&
                    infoSupplier.address_info != undefined
                  "
                  :status.sync="order.mainStatus"
                  :cartlist.sync="cartlist"
                  :order.sync="order"
                  @done="getOrder"
                  @update:status="internalChange = true"
                  @subtotalOrder="order.subtotal = $event"
                  @totalOrder=";(order.total = $event), (internalChange = true)"
                  @taxOrder="order.tax = $event"
                  @addProd="addProducts"
                  :infoSupplier="infoSupplier === null ? '' : infoSupplier"
                />
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
        </v-card-text>
        <v-card-actions
          class="white"
          style="position: fixed; bottom: 0; right: 0"
        >
          <span class="mr-1 primary--text font-weight-black subtitle-2">
            {{ `${$tc('purchasesReceiver', 1)}: ` }}
          </span>
          <span class="mr-2 primary--text subtitle-1">
            {{ order.name_purchasesReceiver }}
          </span>
        </v-card-actions>
      </form>
    </ValidationObserver>
  </v-card>
</template>
<script>
import { mapGetters } from 'vuex'
import cart from '../product/index.vue'
import purchasedocument from '../../components/purchase/purchaseDocument.vue'
import shoplist from '../../components/product/shoplist.vue'
import dialsupplier from '../supplier/_pk.vue'

export default {
  components: {
    cart,
    shoplist,
    dialsupplier,
    purchasedocument
  },
  data() {
    return {
      infoSupplierfield: false,
      loadingIMG: false,
      dialog: false,
      step: 1,
      menu: false,
      menu1: false,
      load: false,
      loading: false,
      isLoading: false,
      infoSupplier: null,
      countries: [],
      suppliers: [],
      searchSupplier: '',
      internalChange: false,
      showCart: false,
      cartlist: [],
      imageUrl: null,
      notification: true,
      orderImage: null,
      discrepancies: [],
      firstCompany: null,
      order: {
        pk: '',
        nameOrder: '',
        date: '',
        code: '',
        supplier: null,
        purchasesReceiver: '',
        name_purchasesReceiver: '',
        mainStatus: 'draft',
        tax: 0,
        subtotal: 0,
        total: 0,
        reception: 0,
        dateDone: null,
        expected_date: null,
        thumbnail: null,
        company_id: null
      }
    }
  },
  watch: {
    cartlist(val) {
      if (val.length > 0) {
        this.showCart = true
      }
    },
    discrepancies(val) {
      if (val.length > 0) {
        this.showCart = true
      }
    },
    'order.supplier': {
      handler(val) {
        let inx = this.suppliers.findIndex((m) => val == m.pk)
        if (inx > -1) {
          this.infoSupplier = this.suppliers[inx]
          this.getCountry()
        }
      }
    },
    'order.date': {
      handler(val) {
        if (
          this.order.expected_date !== null &&
          this.order.expected_date !== null &&
          new Date(val) > new Date(this.order.expected_date)
        ) {
          this.order.expected_date = null
        }
      }
    },
    internalChange: {
      handler(val) {
        if (val) {
          this.submit()
        }
      }
    }
  },
  mounted() {
    this.getOrder(this.$route.params.code)
  },
  methods: {
    async getCartlist() {
      const cart = await this.$api.purchase.product.show({
        pk: this.order.pk,
        opt: {
          params: {
            order: true
          }
        }
      })
      this.cartlist = cart.data
    },
    changeRoute(item) {
      this.$router.push({
        name: 'supplier-pk',
        params: { pk: this.codification({ code: item.pk }) }
      })
    },
    addSupplier(evt) {
      this.suppliers = [evt]
      this.order.supplier = evt.pk
    },
    async getSupplier() {
      this.isLoading = true
      try {
        const supplier = await this.$api.supplier.list({
          opt: {
            params: {
              company_id: this.firstCompany,
              active: true
            }
          }
        })
        this.suppliers = supplier.data.results
      } finally {
        this.isLoading = false
      }
    },
    async getCountry() {
      if (this.order.supplier != null) {
        for (let i in this.infoSupplier.address_info) {
          let pk = this.infoSupplier.address_info[i].country
          if (this.countries[pk] == undefined) {
            try {
              const country = await this.$api.country.show({
                pk: pk
              })
              this.$set(this.countries, pk, country.data.name)
            } catch (error) {
              //
            }
          }
        }
      }
    },
    back() {
      this.step -= this.step === 3 && this.order.mainStatus !== 'draft' ? 2 : 1
    },
    next() {
      this.notification = false
      if (this.step === 1 && this.order.pk == '') {
        this.$refs.form.validate().then((success) => {
          if (success) {
            this.submit()
          }
        })
      } else {
        if (this.cartlist.length == 0) {
          this.$toast.error(`${this.$tc('select_prod', 1)}`)
        }
        if (this.step === 1 && this.order.mainStatus === 'draft') {
          this.step = 2
        } else {
          if (this.step === 1 && this.order.mainStatus !== 'draft') {
            this.step = 3
          } else {
            if (this.step === 2) {
              if (this.order.mainStatus === 'draft') {
                this.order.mainStatus = 'pdocument'
              }
              this.step = 3
              this.submit()
            } else {
              this.step += 1
            }
          }
        }
      }
    },
    addProducts() {
      this.step = 2
    },
    async getOrder() {
      let validation = this.codification({
        mode: 'decode',
        code: this.$route.params.pk
      })
      this.step = 1
      if (this.$store.getters['utils/getIsloading']) {
        this.$store.dispatch('utils/actionLoading')
        this.$route.meta.title = this.$t('create', { model: '' })
      }
      if (validation !== 'create' || this.order.pk !== '') {
        if (this.order.pk !== '') {
          this.step = 3
        }
        try {
          this.loading = true
          let response = await this.$api.purchase.show({
            pk: this.order.pk !== '' ? this.order.pk : validation
          })
          this.order = response.data
          this.$route.meta.title = this.$t('edit', { model: '' })
        } finally {
          this.loading = false
        }
      } else {
        this.order = {
          pk: '',
          nameOrder: '',
          date: new Date().toISOString().substr(0, 10),
          code: '',
          infoSupplier: null,
          purchasesReceiver: this.me.id,
          name_purchasesReceiver: `${this.me.last_name} ${this.me.first_name[0]}.`,
          mainStatus: 'draft',
          tax: 0,
          subtotal: 0,
          total: 0,
          reception: 0,
          dateDone: null,
          expected_date: null,
          thumbnail: null
        }
      }
      try {
        this.imageUrl = this.order.thumbnail
        this.loadingIMG = false
      } catch (error) {
        this.imageUrl = require('@/assets/default_image.png')
        this.loadingIMG = false
      }
      if (this.order.supplier != null) {
        try {
          let infoSupplier = await this.$api.supplier.show({
            pk: this.order.supplier
          })
          this.suppliers = [infoSupplier.data]
          this.infoSupplier = infoSupplier.data
          this.getCountry()
        } catch (err) {
          this.order.supplier = null
          this.infoSupplier = null
        }
      } else {
        this.getSupplier()
      }
    },
    async submit() {
      this.load = true
      let form_data = new FormData()
      this.order.company_id = this.firstCompany
      for (var key in this.order) {
        if (this.order[key] == null) {
          continue
        }
        if (typeof this.order.thumbnail !== 'string') {
          this.orderImage = this.order.thumbnail
        }
        form_data.append(key, this.order[key])
        if (
          key == 'thumbnail' &&
          (this.order.thumbnail == null ||
            String(this.imageUrl).indexOf('http') != -1 ||
            String(this.order.thumbnail).indexOf(';base64') != -1 ||
            String(this.order.thumbnail).indexOf('http') != -1 ||
            String(this.imageUrl).indexOf('/sources/') != -1)
        ) {
          form_data.delete('thumbnail')
        }
      }
      this.order.pk !== ''
        ? this.$api.purchase.edit({
            pk: this.order.pk,
            form: form_data
          })
        : this.$api.purchase
            .create({
              form: form_data
            })
            .then((response) => {
              if (this.order.pk === undefined || this.order.pk === '') {
                this.order = response.data
                this.step = 2
              } else if (this.order.pk != '') {
                this.getCartlist()
                if (!this.internalChange) {
                  this.dialog = false
                }
                this.internalChange = false
              }
              this.$emit('done')
            })
      if (this.notification === true) {
        this.$toast.success(`${this.$tc('order', 1)} ${this.$tc('edited', 2)}`)
      }
      this.notification = true
      this.load = false
    },
    /**
     * setCompany
     * Método para establecer la primera empresa a la que pertenece el usuario
     *
     * Angelo Osorio <danielking.321 at gmail.com>
     */
    setCompany() {
      if (this.myCompanies.length > 0) {
        this.firstCompany = this.myCompanies[0].id
      }
    }
  },
  computed: {
    ...mapGetters({
      me: 'session/me',
      getPermissions: 'session/getPermissions',
      myCompanies: 'session/myCompanies',
      isAdmin: 'session/isAdmin'
    }),
    orderBtn() {
      return {
        document:
          this.getPermissions([`purchase.view_purchasedocument`]) ||
          this.isAdmin,
        orderstatus:
          this.getPermissions([`purchase.view_purchaseorderstatus`]) ||
          this.isAdmin,
        product: this.getPermissions([`product.view_product`]) || this.isAdmin,
        supplier:
          this.getPermissions([`supplier.view_supplier`]) || this.isAdmin,
        supplierAdd:
          this.getPermissions([`supplier.add_supplier`]) || this.isAdmin,
        terms: this.getPermissions([`base_config.view_term`]) || this.isAdmin
      }
    },
    expected_date: {
      set(newValue) {
        if (this.order.expected_date.length > newValue.length) {
          this.order.expected_date = null
        }
      },
      get() {
        return this.dateFormat(this.order.expected_date)
      }
    },
    datemain: {
      set(newValue) {
        if (this.order.date.length > newValue.length) {
          this.order.date = null
        }
      },
      get() {
        return this.dateFormat(this.order.date)
      }
    }
  },
  created() {
    this.setCompany()
  }
}
</script>
<style lang="sass">
.flex-title
  display: flex
  gap: 10px
  align-items: center
  justify-content: space-between
  text-transform: uppercase
</style>
